import cloneDeep from 'lodash/cloneDeep'
import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function publicPageReducer(state: any = initialState.publicPageReducer, action: any) {
  switch (action.type) {
    case types.FETCHING_DATASET:
      return {
        ...state,
        server: {
          ...state.server,
          fetchingDataSet: true,
          dataSet: undefined,
          errorFetchingDataSet: false
        },
      }
    case types.ERROR_FETCH_DATASET:
      return {
        ...state,
        server: {
          ...state.server,
          fetchingDataSet: false,
          dataSet: undefined,
          errorFetchingDataSet: action.error
        },
      }
    case types.RECEIVE_DATASET:
      return {
        ...state,
        server: {
          ...state.server,
          fetchingDataSet: false,
          dataSet: action.response,
          dataSetFilters: state.dataSetFilters ? state.dataSetFilters : action.response.filters, // only populate fiters on first call
          dataSetFilterParams: action.filters,
          errorFetchingDataSet: false
        },
      }
    case types.FETCHING_LOADMORE_DATASET:
      return {
        ...state,
        server: {
          ...state.server,
          fetchingLoadMoreDataSet: true,
          errorFetchingLoadMoreDataSet: false
        }
      }
    case types.ERROR_FETCH_LOADMORE_DATASET:
      return {
        ...state,
        server: {
          ...state.server,
          fetchingLoadMoreDataSet: false,
          errorFetchingLoadMoreDataSet: action.error
        }
      }
    case types.RECEIVE_LOADMORE_DATASET:
      let dataSet = cloneDeep(state.dataSet)
      dataSet.nextCursor = action.response.nextCursor
      dataSet.posts = [...state.dataSet.posts, ...action.response.posts]
      return {
        ...state,
        server: {
          ...state.server,
          fetchingLoadMoreDataSet: false,
          dataSet,
          errorFetchingLoadMoreDataSet: false
        }
      }
    case types.CHANGE_DATA_SET:
      return {
        ...state,
        server: {
          ...state.server,
          dataSet: action.dataset,
          dataSetFilters: action.dataset.filters
        }
      }
    default:
      return state;
  }
}