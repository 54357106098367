import initialState from "../initialState"

export default function mediaLibraryReducer(
  state = initialState.mediaLibraryReducer,
  action: any
) {
  switch (action.type) {
    default:
      return state
  }
}
