import { HYDRATE } from "next-redux-wrapper";
import { AnyAction } from "redux";
import * as types from '../shared-store/actions/actionTypes';
import initialState from "../initialState";

const innerFunctionsReducer = (state: any = initialState.innerFunctionsReducer, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        client: {
          ...state.client,
          ...action.payload.innerFunctionsReducer.client
        }
      }
    case types.TOGGLE_MODAL:
      return {
        ...state,
        client: {
          ...state.client,
          modalStateFromRedux: action.payload
        }
      }
    default:
      return state;
  }
}

export default innerFunctionsReducer;