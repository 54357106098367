import { HYDRATE } from "next-redux-wrapper"
import * as types from "../shared-store/actions/actionTypes"
import initialState from "../initialState"

export default function contentLibraryReducer(state: any = initialState.contentLibraryReducer, action: any) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
      }
    case types.SUCCESS_FETCH_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: false,
        errorFetchPreviewContentPagePost: false,
        successFetchPreviewContentPagePost: action.response,
      }
    case types.ERROR_FETCH_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: false,
        errorFetchPreviewContentPagePost: action.error,
        successFetchPreviewContentPagePost: undefined,
      }
    case types.REQUEST_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: true,
        errorFetchPreviewContentPagePost: false,
        successFetchPreviewContentPagePost: undefined,
      }
    case types.CLEAN_FETCH_PREVIEW_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPreviewContentPagePost: false,
        errorFetchPreviewContentPagePost: false,
        successFetchPreviewContentPagePost: undefined,
      }
    /*** */
    case types.SUCCESS_FETCH_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: false,
        errorFetchPublicContentPagePost: false,
        successFetchPublicContentPagePost: action.response,
      }
    case types.ERROR_FETCH_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: false,
        errorFetchPublicContentPagePost: action.error,
        successFetchPublicContentPagePost: undefined,
      }
    case types.REQUEST_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: true,
        errorFetchPublicContentPagePost: false,
        successFetchPublicContentPagePost: undefined,
      }
    case types.CLEAN_FETCH_PUBLIC_CONTENT_PAGE_POST:
      return {
        ...state,
        loadingPublicContentPagePost: false,
        errorFetchPublicContentPagePost: false,
        successFetchPublicContentPagePost: undefined,
      }
    /*** */
    default:
      return state
  }
}
