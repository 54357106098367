import { HYDRATE } from 'next-redux-wrapper'
import * as types from '../shared-store/actions/actionTypes'
import initialState from '../initialState'

export default function contentHubReducer(state: any = initialState.contentHubReducer, action: any) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        server: {
          ...state.server,
          ...action.payload.contentHubReducer.server,
        },
      }
    case types.REQUEST_HUB_CONTENT:
      return {
        ...state,
        server: {
          isFetching: true,
          isInvalid: false,
          hubId: action.hubId,
          local: action.local,
        },
      }
    case types.RECEIVE_HUB_CONTENT:
      let hubResponse = action.hubContent.response || action.hubContent
      return {
        ...state,
        isFetching: false,
        isInvalid: false,
        hubId: action.hubId,
        hubContent: hubResponse,
      }
    case types.CLEAN_HUB_CONTENT:
      return {
        ...state,
        isInvalid: false,
        hubId: null,
        hubContent: null,
      }
    case types.INVALID_HUB_ID:
      return {
        ...state,
        isFetching: false,
        isInvalid: true,
      }
    case types.LIVE_EDIT_FORMULA:
      return {
        ...state,
        hubContent: action.object,
      }
    case types.EDIT_HUB_TEMPLATE_INTERNAL_REDUX:
      return {
        ...state,
        isFetching: false,
        isInvalid: false,
        hubContent: action.hub.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.INVALID_EXPERIENCE:
      return {
        ...state,
        server: {
          ...state.server,
          invalidExperience: action.error,
          loadingExperience: false,
          experience: null,
        },
      }
    case types.REQUEST_EXPERIENCE:
      return {
        ...state,
        server: {
          ...state.server,
          invalidExperience: false,
          loadingExperience: true,
          experience: null,
        },
      }
    case types.RECEIVE_EXPERIENCE:
      const experienceResponse = action.response.response || action.response
      return {
        ...state,
        server: {
          ...state.server,
          invalidExperience: false,
          loadingExperience: false,
          experience: {
            ...experienceResponse,
            pageData: { ...experienceResponse.pageData, webpSupport: action.webpSupport === "true" ? true : false },
          },
        },
      }
    case types.REPLACE_EXPERIENCE:
      return {
        ...state,
        experience: action.experience,
      }
    case types.CONTENT_FETCHED:
      return {
        ...state,
        [`content${action.objectUuid}`]: true,
        [`content${action.objectUuid}content`]: action.response.response.content,
        [`content${action.objectUuid}contentMedia`]: action.response.media,
        [`content${action.objectUuid}loading`]: false,
      }
    case types.FETCHING_CONTENT:
      return {
        ...state,
        [`content${action.objectUuid}loading`]: true,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_FETCH_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        isFetching: false,
        isInvalid: action.error,
        hubId: null,
        hubContent: null,
      }
    case types.REQUEST_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        isFetching: true,
        isInvalid: false,
        hubId: null,
        hubContent: null,
      }
    case types.SUCCESS_FETCH_OBJECT_TEMPLATE_BY_ID:
      return {
        ...state,
        isFetching: false,
        isInvalid: false,
        hubId: action.response.rootId,
        hubContent: action.response,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_OBJECT_TEMPLATE_TO_CLONE:
      return {
        ...state,
        loadingObjectTemplateToClone: false,
        errorObjectTemplateToClone: action.error,
        objectTemplateToClone: null,
        objectTemplateToCloneGroup: null,
      }
    case types.REQUEST_OBJECT_TEMPLATE_TO_CLONE:
      return {
        ...state,
        loadingObjectTemplateToClone: true,
        errorObjectTemplateToClone: false,
        objectTemplateToClone: null,
        objectTemplateToCloneGroup: null,
      }
    case types.SUCCESS_OBJECT_TEMPLATE_TO_CLONE:
      return {
        ...state,
        loadingObjectTemplateToClone: false,
        errorObjectTemplateToClone: false,
        objectTemplateToClone: action.response,
        objectTemplateToCloneGroup: action.groupId,
      }
    case types.CLEAR_OBJECT_TEMPLATE_TO_CLONE:
      return {
        ...state,
        loadingObjectTemplateToClone: false,
        errorObjectTemplateToClone: false,
        objectTemplateToClone: null,
        objectTemplateToCloneGroup: null,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_FETCH_EXPERIENCE_PREVIEW:
      return {
        ...state,
        invalidExperience: action.error,
        loadingExperience: false,
        experience: null,
        experienceId: null,
      }
    case types.REQUEST_FETCH_EXPERIENCE_PREVIEW:
      return {
        ...state,
        invalidExperience: false,
        loadingExperience: true,
        experience: null,
        experienceId: null,
      }
    case types.SUCCESS_FETCH_EXPERIENCE_PREVIEW:
      return {
        ...state,
        invalidExperience: false,
        loadingExperience: false,
        experience: action.response,
        experienceId: action.response.uuid,
      }
    case types.CLEAR_FETCH_EXPERIENCE_PREVIEW:
      return {
        ...state,
        invalidExperience: false,
        loadingExperience: false,
        experience: null,
        experienceId: null,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case types.ERROR_FETCH_EXPERIENCE_SYMBOL:
      return {
        ...state,
        isFetching: false,
        isInvalid: action.error,
        hubId: null,
        hubContent: null,
      }
    case types.REQUEST_FETCH_EXPERIENCE_SYMBOL:
      return {
        ...state,
        isFetching: true,
        isInvalid: false,
        hubId: null,
        hubContent: null,
      }
    case types.SUCCESS_FETCH_EXPERIENCE_SYMBOL:
      return {
        ...state,
        isFetching: false,
        isInvalid: false,
        hubId: action.response.rootId,
        hubContent: action.response,
      }
    case types.CLEAR_FETCH_EXPERIENCE_SYMBOL:
      return {
        ...state,
        isFetching: false,
        isInvalid: false,
        hubId: null,
        hubContent: null,
      }
    default:
      return state
  }
}
