import { HYDRATE } from 'next-redux-wrapper';
import * as types from '../shared-store/actions/actionTypes';
import initialState from '../initialState';

export default function pageTreeReducer(state: any = initialState.pageTreeReducer, action: any) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        server: {
          ...state.server,
          ...action.payload.pageTreeReducer.server
        }
      }
    case types.PAGE_TREE_FINDER_CONTROLLER:
      return {
        ...state,
        server: {
          ...state.server,
          finderState: action.symbolObj
        }
      }
    case types.PAGE_TREE_EDIT_SYMBOL_STATE:
      return {
        ...state,
        server: {
          ...state.server,
          symbolObj: action.symbolObj,
        },
        symbolFinderTab: action.tabToOpen
      }
    case types.PAGE_TREE_FINDER_EXPANDED:
      return {
        ...state,
        server: {
          ...state.server,
          isExpanded: action.isExpanded
        }
      }
    default:
      return state;
  }
}