import LocalStorageHandler from "../modules/shared-modules/utilities/localStorage"
import * as viewPort from "../modules/shared-modules/experienceManager/viewPortTypes/viewPortTypes"
import { CONTENT_TYPE_INSTANCE } from "../modules/shared-modules/experienceManager/finder/symbols/symbolTypes"

const initialState = {
  contentHubReducer: {
    server: {
      isFetching: false,
      hubContent: null,
    },
  },
  selectedHub: {
    server: {
      hubId: null,
    },
  },
  experienceManagerReducer: {
    server: {
      hubHandlerId: null,
      loadingHubs: false,
      newPageModalState: false,
      eventPile: [],
      colorsObj: {
        first: "#000000",
        second: "#000000",
        background: "#000000",
        backgroundAlt: "#000000",
      },
      finderPreview: false,
      selectedPostsEM: [],
      objectSelectorModeEM: true,
      successResponsesEventPile: [],
      warningState: false,
      elementSelectorStateEM: true,
      objectSelectorStateEM: true,
      globalStyleState: "list",
      viewPortSizeState: viewPort.DESKTOP,
      viewPortScaleState: 100,
      responsiveTab: "responsive",
      arrangement_clp: true,
      content_clp: true,
      style_clp: true,
      behaviourState: "default",
      isContentPage: false,
    },
  },
  globalStyleReducer: {
    server: {
      globalStyleBehaviour: "default",
    },
  },
  globalStylesReducer: {},
  pageTreeReducer: {
    server: {
      symbolObj: null,
      finderState: false,
      isExpanded: LocalStorageHandler.getValue("pageTreeIsExpanded") === "false" ? false : true,
    },
  },
  symbolsReducer: {
    server: {
      selectedSymbolInstanceId: null,
      symbolContentType: CONTENT_TYPE_INSTANCE,
    },
  },
  publicPageReducer: {
    server: {
      fetchingDataSet: false,
      dataSet: null,
      errorFetchingDataSet: null,
      dataSetParams: null,
    },
  },
  contentLibraryReducer: {
    loadingPublicContentPagePost: false,
    loadingPreviewContentPagePost: false,
  },
  ssoReducer: {},
  innerFunctionsReducer: {
    client: {
      modalStateFromRedux: false,
    },
  },
  mediaLibraryReducer: {
    replacedMediaList: {},
  },
}

export default initialState
