import "../stylesheets/public.sass"
import React from "react"
import Script from "next/script"
import { wrapper } from "../store/configureStore"
import type { AppProps } from "next/app"

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script
        src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.slim.min.js'
        integrity='sha512-6ORWJX/LrnSjBzwefdNUyLCMTIsGoNP6NftMy2UAm1JBm6PRZCO1d7OHBStWpVFZLO+RerTvqX/Z9mBFfCJZ4A=='
        crossOrigin='anonymous'
        referrerPolicy='no-referrer'
        strategy='lazyOnload'
      />
      <Script
        src='https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js'
        integrity='sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1'
        crossOrigin='anonymous'
        strategy='lazyOnload'
      />
      <Script
        src='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js'
        integrity='sha256-CjSoeELFOcH0/uxWu6mC/Vlrc1AARqbm/jiiImDGV3s='
        crossOrigin='anonymous'
        strategy='lazyOnload'
      />
      <Component {...pageProps} />
    </>
  )
}

export default wrapper.withRedux(MyApp)
