import {
  SELECTED_HUB_HANDLER_TO_EDIT,
  SELECTED_FINDER,
  CLEAR_SELECTED_FINDER,
  SELECTED_FINDER_TYPE,
  EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER,
  OBJECT_SELECTOR_MODE_EM,
  OBJECT_SELECTOR_HOVER,
  WARNING_STATE_EM,
  OBJECT_SELECTOR_STATE,
} from "../shared-store/actions/actionTypes"
import initialState from "../initialState"
import { HYDRATE } from "next-redux-wrapper"
import { AnyAction } from "redux"

export default function experienceManagerReducer(
  state: any = initialState.experienceManagerReducer,
  action: AnyAction
) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        server: {
          ...state.server,
          ...action.payload.experienceManagerReducer.server,
        },
      }
    case SELECTED_HUB_HANDLER_TO_EDIT:
      return {
        ...state,
        server: {
          ...state.server,
          hubHandlerId: action.id,
          hubHandlerObject: action.object,
          hubHandlerParentId: action.parentId,
        },
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case OBJECT_SELECTOR_MODE_EM:
      return {
        ...state,
        server: {
          ...state.server,
          objectSelectorModeEM: action.value,
        },
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case OBJECT_SELECTOR_HOVER:
      return {
        ...state,
        objectSelectorHover: action.value,
      }
    case OBJECT_SELECTOR_STATE:
      return {
        ...state,
        server: {
          ...state.server,
          objectSelectorStateEM: action.objectState,
        },
      }
    case SELECTED_FINDER:
      return {
        ...state,
        finderType: action.typeSelected,
        finderSelectedObject: action.selectedObject,
      }
    case CLEAR_SELECTED_FINDER:
      return {
        ...state,
        finderType: false,
        finderSelectedObject: undefined,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case SELECTED_FINDER_TYPE:
      return {
        ...state,
        finderType: action.typeSelected,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case EDITING_FINDER_OBJECT_EXPERIENCE_MANAGER:
      return {
        ...state,
        editingFinderObjectEM: action.object,
        editingFinderObjectEventEM: action.event,
        finderType:
          action.event !== "no-event-register"
            ? action.finderTab
              ? action.finderTab
              : action.event
            : state.finderType,
        finderSelectedObject: action.selectFinderObject ? action.object : state.finderSelectedObject,
      }
    /* -- * -- * -- * -- * -- * -- * -- * -- */
    case WARNING_STATE_EM:
      return {
        ...state,
        server: {
          ...state.server,
          warningState: action.value,
        },
      }
    default:
      return state
  }
}
