import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { createLogger } from 'redux-logger';

import rootReducers from './reducers/rootReducer';
import { AppStore } from './types';
import initialState from "./initialState"

const logger = createLogger({
  predicate: (getState, action) =>
    process.env.NODE_ENV === `development` && false,
})

const makeStore = () =>
  configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  })

const wrapper = createWrapper<AppStore>(makeStore, { debug: false })

export { makeStore, wrapper };