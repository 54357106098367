import { combineReducers } from "redux";
import contentHubReducer from "./contentHubReducer"
import contentLibraryReducer from "./contentLibraryReducer";
import experienceManagerReducer from "./experienceManagerReducer";
import pageTreeReducer from "./pageTreeReducer";
import symbolsReducer from "./symbolsReducer"
import innerFunctionsReducer from "./innerFunctionsReducer"
import publicPageReducer from "./publicPageReducer"
import mediaLibraryReducer from "./mediaLibraryReducer"

const rootReducers = combineReducers({
  contentHubReducer,
  contentLibraryReducer,
  experienceManagerReducer,
  pageTreeReducer,
  symbolsReducer,
  innerFunctionsReducer,
  publicPageReducer,
  mediaLibraryReducer,
})

export default rootReducers; 